import store from '@/store'

export default {
  namespaced: true,
  state: {
    menu: [
      {
        id: 'home',
        text: 'Home',
        to: '/',
        showEvery: true
      },
      {
        id: 'signIn',
        text: 'Sign in',
        to: '/login',
        showWhenLogged: false
      },
      {
        id: 'signUp',
        text: 'Sign up',
        to: '/register',
        showWhenLogged: false
      },
      {
        id: 'newArticle',
        text: 'New article',
        to: '/editor',
        icon: 'file-earmark-text',
        showWhenLogged: true
      },
      {
        id: 'settings',
        text: 'Settings',
        to: '/settings',
        icon: 'gear',
        showWhenLogged: true
      },
      {
        id: 'profile',
        textVariable: 'username',
        toVariable: 'username',
        showWhenLogged: true,
        image: () => store.getters['user/data']['image']
      },
    ]
  },
  getters: {
    menu: state => state.menu
  },
  mutation: {
    menu: (state, payload) => {
      state.menu = payload
    }
  }
}