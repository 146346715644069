<template>
  <b-container fluid id="app">
    <AppMenu/>
    <router-view/>
    </b-container>
</template>
<script>

import AppMenu from '@/components/Menu.vue'

export default {
  components: {
    AppMenu
  },
  async created () {
    if (this.getStore('jwtToken')) {
      const user = await this.$http.currentUser()
        .then( ({ data }) => data.user )
      this.setStore('user/data', user)
      this.setStore('user/jwtToken', user.token)
    }

  }
}
</script>

<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.nav {
  &__title {
    font-size: 1.5rem;
    font-family: "Titillium Web", sans-serif;
    color: #5CB85C !important;
    font-weight: 700 !important;
    text-decoration: none !important;
  }
  &__link {
    text-decoration: none !important;
    color: lighten($dark, 60%);
    &.router-link-exact-active, &:hover {
      color: $dark !important;
    }
  }
}
</style>
