import { Middleware } from "../middleware"
import store from '@/store'
const jwtToken = () => store.getters['user/jwtToken']

export default {
  login (data) {
    return new Middleware()
      .method('POST')
      .append('/users/login')
      .setData(data)
      .build()
  },
  register (data) {
    return new Middleware()
      .method('POST')
      .append('/users')
      .setData(data)
      .build()
  },
  currentUser () {
    return new Middleware()
      .method('GET')
      .append('/user')
      .contentAuthorization(jwtToken())
      .build()
  },
  feedUser (params) {
    return new Middleware()
      .method('GET')
      .append('/articles/feed')
      .contentAuthorization(jwtToken())
      .params(params)
      .build()
  },
  updateUser (body) {
    return new Middleware()
      .method('PUT')
      .append('/user')
      .contentAuthorization(jwtToken())
      .setData(body)
      .build()
  }
}