import { Middleware } from "../middleware"
// import store from '@/store'
// const jwtToken = store.getters['user/jwtToken']

import apiUser from './user'
import apiArticles from './articles'
import apiProfile from './profile'

export default {
  tags () {
    return new Middleware()
      .method('GET')
      .append('/tags')
      .build()
  },
  ...apiProfile,
  ...apiArticles,
  ...apiUser
}