import { Middleware } from "../middleware"
import store from '@/store'
const jwtToken = () => store.getters['user/jwtToken']

export default {
  articles (params) {
    return new Middleware()
      .method('GET')
      .contentAuthorization(jwtToken())
      .append('/articles')
      .params(params)
      .build()
  },
  getArticle (slug) {
    return new Middleware()
      .method('GET')
      .append(`/articles/${slug}`)
      .build()
  },
  createArticle (data) {
    return new Middleware()
      .method('POST')
      .contentAuthorization(jwtToken())
      .append('/articles')
      .setData(data)
      .build()
  },
  updateArticle (slug, data) {
    return new Middleware()
      .method('PUT')
      .contentAuthorization(jwtToken())
      .append(`/articles/${slug}`)
      .setData(data)
      .build()
  },
  deleteArticle (slug) {
    return new Middleware()
      .method('DELETE')
      .contentAuthorization(jwtToken())
      .append(`/articles/${slug}`)
      .build()
  },
  getCommentsArticle (slug) {
    return new Middleware()
      .method('GET')
      .contentAuthorization(jwtToken())
      .append(`/articles/${slug}/comments`)
      .build()
  },
  deleteCommentArticle (slug, commentId) {
    return new Middleware()
      .method('DELETE')
      .contentAuthorization(jwtToken())
      .append(`/articles/${slug}/comments/${commentId}`)
      .build()
  },
  createComment (slug, data) {
    return new Middleware()
      .method('POST')
      .contentAuthorization(jwtToken())
      .append(`/articles/${slug}/comments`)
      .setData(data)
      .build()
  },
  favoriteArticle (slug) {
    return new Middleware()
      .method('POST')
      .contentAuthorization(jwtToken())
      .append(`/articles/${slug}/favorite`)
      .build()
  },
  unfavoriteArticle (slug) {
    return new Middleware()
      .method('DELETE')
      .contentAuthorization(jwtToken())
      .append(`/articles/${slug}/favorite`)
      .build()
  }
}