export default {
  namespaced: true,
  state: {
    data: undefined,
    jwtToken: undefined
  },
  getters: {
    data: state => state.data,
    jwtToken: state => state.jwtToken
  },
  mutations: {
    data: (state, payload) => {
      state.data = payload
    },
    jwtToken: (state, payload) => {
      state.jwtToken = payload
    }
  }
}