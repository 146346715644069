<template>
  <b-container>
    <b-row class="nav py-2 align-items-end">
      <b-col class="d-flex justify-content-start">
        <router-link to="/" custom v-slot="{ href, navigate }">
          <a class="nav__title" :href="href" @click="navigate">conduit</a>
        </router-link>
      </b-col>
      <span v-for="item in getMenuList"
      :key="item.text"
      v-show="item.showWhenLogged === userHasLogged || item.showEvery"
      class="justify-content-end ml-3">
        <router-link
        v-if="item.showWhenLogged === userHasLogged || item.showEvery"
        class="py-2 nav__link"
        :to="defineRoute(item)">
          <b-img v-if=item.image :src="item.image()" class="img-fill-height"/>
          <b-icon v-if=item.icon :icon="item.icon"/>
          {{ defineText(item)}}
        </router-link>
      </span>
    </b-row>
  </b-container>
</template>

<script>
export default {
  computed: {
    getMenuList () {
      return this.getStore('global/menu')
    },
    userHasLogged () {
      return this.getStore('user/data') !== undefined
    }
  },
  methods: {
    defineRoute ({ to, toVariable }) {
      if (to) return to
      const variables = {
        username: `/@${this.getStore('user/data', 'username')}`
      }
      return variables[toVariable]
    },
    defineText ({ text, textVariable }) {
      if (text) return text
      const variables = {
        username: this.getStore('user/data', 'username')
      }
      return variables[textVariable]
    }
  }
}
</script>

<style lang="scss">
  .nav {
    &__link {
      img {
        height: 26px;
        border-radius: 50%;
        width: 26px;
        object-fit: cover;
      }
    }
  }
</style>