import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './utils/globalComponents'
import './utils/filters'
import './utils/prototype'
import '@/assets/scss/main.scss'
import globalMixin from '@/utils/globalMixin'
import VueMeta from 'vue-meta'


Vue.config.productionTip = false
Vue.mixin(globalMixin)
Vue.use(VueMeta)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
