import { Middleware } from "../middleware"
import store from '@/store'
const jwtToken = () => store.getters['user/jwtToken']

export default {
  getProfile (username) {
    return new Middleware()
      .method('GET')
      .append(`/profiles/${username}`)
      .contentAuthorization(jwtToken())
      .build()
  },
  unfollowingProfile (username) {
    return new Middleware()
      .method('DELETE')
      .append(`/profiles/${username}/follow`)
      .contentAuthorization(jwtToken())
      .build()
  },
  followingProfile (username) {
    return new Middleware()
      .method('POST')
      .append(`/profiles/${username}/follow`)
      .contentAuthorization(jwtToken())
      .build()
  }
}