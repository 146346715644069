export default {
  computed: {
    userHasLogged () {
      return this.$store.getters['user/data'] !== undefined
    }
  },
  methods: {
    setStore (id, value) {
      this.$store.commit(id, value)
    },
    getStore (id, ...args) {
      try {
        const store = this.$store.getters[id]
        if (args.length === 1) {
          return store[args[0]]
        } else if (args.length > 1) {
          const newStore = args.reduce((prev, current) => {
            if (typeof prev === 'object') {
              return prev[current]
              
            }
            return store[prev][current]
          })
          return newStore
        }
        return store
      } catch (err) {
        return
      }
    },
    async updateLastInfoUser () {
      await this.$http.currentUser().then(({ data }) => {
        this.setStore('user/data', data.user)
      })
    }
  },
}