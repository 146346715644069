import Vue from 'vue'
import VuexPersist from 'vuex-persist'

import Vuex from 'vuex'

//! MODULES
import global from './global'
import user from './user'

const vuexLocalPersist = new VuexPersist({
  key: 'test-almocogratis',
  modules: [ 'user' ],
  storage: localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    global
  },
  plugins: [vuexLocalPersist.plugin]
})
