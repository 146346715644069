import Vue from 'vue'

import {
  BIcon,
  BIconGear,
  BIconFileEarmarkText,
  BIconHeart,
  BIconPlus,
  BIconPencilFill,
  BIconTrash
} from 'bootstrap-vue'

// Application more fast when import specific components
Vue.component('BIcon', BIcon)
Vue.component('BIconPlus', BIconPlus)
Vue.component('BIconGear', BIconGear)
Vue.component('BIconFileEarmarkText', BIconFileEarmarkText)
Vue.component('BIconHeart', BIconHeart)
Vue.component('BIconPencilFill', BIconPencilFill)
Vue.component('BIconTrash', BIconTrash)